import React, { useState, useEffect } from 'react';
import { Button, Dialog, Box, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox, Grid, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from '../../api/axios';
import domain from "../../api/constent";
import Session from '../../api/session';

import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";

// Main Component
export default function LicensesAComp() {
    const [openDialog, setOpenDialog] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);  // To toggle between Create and Edit mode
    const [formData, setFormData] = useState({
        licenseName: '',
        issuingOrganisation: '',
        issueDate: '',
        expiryDate: '',
        noExpiry: false,
        description: ''
    });

    const [errors, setErrors] = useState({
        licenseName: '',
        issuingOrganisation: '',
        issueDate: '',
        expiryDate: '',
        description: ''
    });

    const [licenses, setLicenses] = useState([]);  // State to hold the list data
    const [isLoading, setLoading] = useState(true);  // Loading state

    useEffect(() => {
        const userId = Session.getDataSessionStore().id;
        const userEmail = Session.getDataSessionStore().email;

        // Fetch data from API on component mount
        axios.get(`${domain.Licenses_List}${userId}/${userEmail}`)
            .then(response => {
                setLicenses(response.data.data); // Set list data from the API response
                setLoading(false); // Set loading to false once data is fetched
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        if (value) {
            setErrors({ ...errors, [field]: '' });
        }
    };

    const handleOpenDialog = (license = null) => {
        if (license) {
            // Editing an existing license
            setIsEditMode(true);
            setFormData({
                licenseName: license.license_name,
                issuingOrganisation: license.issue_organization,
                issueDate: license.issue_date,
                expiryDate: license.no_expiry ? '' : license.expiry_date,
                noExpiry: license.no_expiry,
                description: license.description
            });
        } else {
            // Creating a new license
            setIsEditMode(false);
            setFormData({
                licenseName: '',
                issuingOrganisation: '',
                issueDate: '',
                expiryDate: '',
                noExpiry: false,
                description: ''
            });
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        const newErrors = {};
        if (!formData.licenseName) newErrors.licenseName = 'License Name is required';
        if (!formData.issuingOrganisation) newErrors.issuingOrganisation = 'Issuing Organisation is required';
        if (!formData.issueDate) newErrors.issueDate = 'Issue Date is required';
        if (!formData.noExpiry && !formData.expiryDate) newErrors.expiryDate = 'Expiry Date is required';
        if (!formData.description) newErrors.description = 'Description is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            if (isEditMode) {
                setLoading(true);
                // Update License API call
                axios.post(`${domain.Candidate_Licenses_Edit}/${formData.id}`, formData)
                    .then(response => {
                        setLicenses(licenses.map(license => license.id === formData.id ? response.data : license));
                        setOpenDialog(false);
                    })
                    .catch(error => {
                        console.error('Error updating license:', error);
                    });

                setLoading(false);
            } else {
                // Create License API call

                setLoading(true);
                axios.post(domain.Candidate_Licenses_Create, formData)
                    .then(response => {
                        setLicenses([...licenses, response.data]);
                        setOpenDialog(false);
                    })
                    .catch(error => {
                        console.error('Error creating license:', error);
                    });

                setLoading(false);
            }
        }
    };

    const styles = {
        card: {
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '16px',
            margin: '16px 0',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        licenseName: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        editButton: {
            background: 'transparent',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
        },
        verifyLink: {
            color: '#007bff',
            textDecoration: 'none',
            fontSize: '16px',
        },
        institution: {
            fontSize: '16px',
            color: '#555',
        },
        description: {
            fontSize: '14px',
            color: '#333',
        },
        expiryDate: {
            fontSize: '14px',
            color: '#888',
        },
        finishedYear: {
            fontSize: '14px',
            color: '#888',
        },
    }

    return (
        <div>
            <div className="pt-2">
                <strong>Licenses & Certifications</strong>
                <p>Showcase your professional credentials. Add your relevant licenses, certificates, memberships, and accreditations here.</p>
                {isLoading ? (
                    // Shimmer effect for loading state
                    <ShimmerCategoryItems mode="light" />

                ) : (
                    licenses.map((license) => (
                        <div style={styles.card} key={license.id}>
                            <div style={styles.header}>
                                <h3 style={styles.licenseName}>{license.license_name}</h3>
                                <button style={styles.editButton} onClick={() => handleOpenDialog(license)} title="Edit">
                                    ✏️
                                </button>
                            </div>
                            <p style={styles.institution}>{license.issue_organization}</p>
                            <p style={styles.finishedYear}>
                                Issued: {new Date(license.issue_date).toLocaleDateString()}
                            </p>
                            <p style={styles.expiryDate}>
                                Expiry Date: {license.no_expiry ? 'No Expiry' : new Date(license.expiry_date).toLocaleDateString()}
                            </p>
                            <p style={styles.description}>{license.description}</p>
                        </div>
                    ))
                )}
                <Button size="small" variant="contained" onClick={() => handleOpenDialog()}>Add Licenses & Certifications</Button>
            </div>

            {/* Licenses & Certifications Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <strong>{isEditMode ? 'Edit License & Certification' : 'Add License & Certification'}</strong>
                        <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        {/* License Name */}
                        <Grid item xs={12}>
                            <TextField
                                label="License Name"
                                variant="outlined"
                                fullWidth
                                value={formData.licenseName}
                                onChange={(e) => handleChange('licenseName', e.target.value)}
                                error={!!errors.licenseName}
                                helperText={errors.licenseName || 'Enter the name of the license or certification'}
                                placeholder="Enter License Name"
                            />
                        </Grid>

                        {/* Issuing Organisation */}
                        <Grid item xs={12}>
                            <TextField
                                label="Issuing Organisation"
                                variant="outlined"
                                fullWidth
                                value={formData.issuingOrganisation}
                                onChange={(e) => handleChange('issuingOrganisation', e.target.value)}
                                error={!!errors.issuingOrganisation}
                                helperText={errors.issuingOrganisation || 'Enter the name of the issuing organisation'}
                                placeholder="Enter Issuing Organisation"
                            />
                        </Grid>

                        {/* Issue Date */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Issue Date"
                                variant="outlined"
                                fullWidth
                                type="date"
                                value={formData.issueDate}
                                onChange={(e) => handleChange('issueDate', e.target.value)}
                                error={!!errors.issueDate}
                                helperText={errors.issueDate || 'Select the issue date'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        {/* Expiry Date */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Expiry Date"
                                variant="outlined"
                                fullWidth
                                type="date"
                                disabled={formData.noExpiry}
                                value={formData.expiryDate}
                                onChange={(e) => handleChange('expiryDate', e.target.value)}
                                error={!!errors.expiryDate}
                                helperText={errors.expiryDate || 'Select the expiry date'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        {/* No Expiry Checkbox */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.noExpiry}
                                        onChange={(e) => handleChange('noExpiry', e.target.checked)}
                                    />
                                }
                                label="No Expiry"
                            />
                        </Grid>

                        {/* Description */}
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                value={formData.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                error={!!errors.description}
                                helperText={errors.description || 'Enter a brief description'}
                                placeholder="Enter Description"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSave} variant="contained" color="primary">
                        {isEditMode ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
