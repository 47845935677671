import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SelectLocation from "../dropdown";
import CircleProgess from "../progressBar";
import '../../assets/css/styles.css';
import SearchSelect from "../search-selection/SearchSelect";
import axios from "../../api/axios";
import domain from "../../api/constent";
import Select from 'react-select';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
Banner04.propTypes = {};

function Banner04(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [options, setEmpty] = useState([]);
  const [JobCategoryOptions, setOptions] = useState([]);
  useEffect(() => {
    fetchCategories();

  }, []);
  const fetchCategories = async () => {
    try {
      const response = await axios.get(domain.Job_Category_URL);

      // Map response data to the desired format
      const categoryOptions = response.data.data.map((category) => ({
        value: category.category_id,
        label: category.category_name
      }));

      setOptions(categoryOptions); // Set the mapped data to options
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };


  // Debounced function for handling input change
  const debouncedLoadOptions = debounce((inputValue) => {
    return fetchJobKeywords(inputValue);
  }, 500);

  // Fetch job keywords
  const fetchJobKeywords = async (keyword) => {
    try {
      const response = await axios.get(`http://localhost:8000/api/job-keyword?keyword=${keyword}`);
      
      const keywordOptions = response.data.map((data) => ({
        value: data.value,
        label: data.label // Ensure this matches your API response
      }));
      return keywordOptions; // Return the options for AsyncSelect
    } catch (error) {
      console.error('Error fetching job keywords:', error);
      return []; // Return an empty array on error
    }
  };

  // Handle select option change
  const handleChange = (option) => {
    setSelectedOption(option);
    console.log('Option selected:', option);
  };

  // Custom styles for the Select component
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: '8px',
      boxShadow: 'none',
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'black' : 'grey',
      backgroundColor: state.isSelected ? 'lightgray' : 'white',
    }),
  };
  // over-flow-hidden
  return (
    <section className="tf-slider sl3 ">
      <div className="tf-container">
        <div className="row text-center">
          <div className="col-lg-2 ">
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="content wow fadeInUp">
              {/* <div className="heading"> */}
              {/* <h2 className="text-white">Find the job that fits your life</h2>
                <p className="text-white">
                  Resume-Library is a true performance-based job board. Enjoy
                  custom hiring products and access to up to 10,000 new resume
                  registrations daily, with no subscriptions or user licences.
                </p> */}
              {/* </div> */}
              <div className="form-sl">
                <form action="/job-list-sidebar">
                  <div className="row-group-search home1 st">
                    <div className="form-group-1 p-2">
                      <AsyncSelect
                        value={selectedOption} // Set the selected option
                        onChange={handleChange} // Handle option change
                        inputValue={inputValue}
                        onInputChange={(newValue) => {
                          setInputValue(newValue);
                          return newValue;
                        }} // Update input value
                        loadOptions={debouncedLoadOptions} // Load options asynchronously
                        placeholder="Enter Keywords"
                        isClearable
                        cacheOptions
                        styles={customStyles} // Apply custom styles
                      />
                      {/* <SearchSelect
                        options={Array.isArray(keyword) ? keyword : []} // Ensure it's always an array
                        placeholder="Enter Keywords"

                        frameType={1}
                        showIcon={true}      // Set to true or false to toggle icon
                        isClearable={true}    // Set to true to allow clearing selection
                      /> */}
                      {/* <span className="icon-search search-job"></span>
                      <input
                        type="text"
                        className="input-filter-search"
                        placeholder="Job title, key words or company"
                      /> */}
                    </div>
                    <div className="form-group-2 p-2">
                      {/* <span className="icon-map-pin"></span> */}
                      {/* <SelectLocation /> */}
                      <SearchSelect
                        options={JobCategoryOptions}
                        frameType={2}
                        placeholder="Any Classification"
                        showIcon={false}      // Set to true or false to toggle icon
                        isClearable={false}    // Set to true to allow clearing selection
                      />
                    </div>
                    <div className="form-group-3 p-2">
                      {/* <span className="icon-search search-job"></span> */}
                      <SearchSelect
                        options={options}
                        placeholder="Enter Suburb,city, or region"
                        frameType={1}
                        showIcon={true}      // Set to true or false to toggle icon
                        isClearable={true}    // Set to true to allow clearing selection
                      />
                      {/* <input
                        type="text"
                        className=""
                        placeholder="Enter suburb, city, region"
                      /> */}
                    </div>
                    <div className="form-group-4">
                      <button type="submit" className="btn btn-find">
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <ul className="list-category text-white">
                <li>
                  <Link to="#">Designer</Link>
                </li>
                <li className="current">
                  <Link to="#">Developer</Link>
                </li>
                <li>
                  <Link to="#">Tester</Link>
                </li>
                <li>
                  <Link to="#">Writing</Link>
                </li>
                <li>
                  <Link to="#">Project Manager</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="wd-review-job thumb2 widget-counter tf-sl3">
              <div className="thumb">
                <div className="box1">
                  <img
                    src={require("../../assets/images/review/thumb5.png")}
                    alt="images"
                  />
                  <div className="markk ani3">
                    <img
                      src={require("../../assets/images/review/shape3.png")}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="box2">
                  <img
                    src={require("../../assets/images/review/thumb6.png")}
                    alt="images"
                  />
                  <div className="markk ani3">
                    <img
                      src={require("../../assets/images/review/shape4.png")}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
              <div className="tes-box ani5">
                <div className="client-box">
                  <div className="avt">
                    <img
                      src={require("../../assets/images/review/client.jpg")}
                      alt="images"
                    />
                    <div className="badge"></div>
                  </div>
                  <div className="content">
                    <h6 className="number wrap-counter">1M+ recruiters</h6>
                    <div className="des">Happpy Candidates</div>
                  </div>
                </div>
              </div>
              <div className="icon1 ani3">
                <img
                  src={require("../../assets/images/review/icon2.png")}
                  alt="images"
                />
              </div>
              <div className="icon2 ani4">
                <img
                  src={require("../../assets/images/review/icon3.png")}
                  alt="images"
                />
              </div>
              <div className="icon3 ani6">
                <img
                  src={require("../../assets/images/review/icon11.png")}
                  alt="images"
                />
              </div>

              <div className="chart-box counter">
                <h6>Candidates</h6>
                <CircleProgess />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner04;
