import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Container, Grid, IconButton } from '@mui/material';

import Header1 from "../../components/header/Header1";
import Banner04 from "../../components/banner/Banner04";
import IconBox from "../../components/iconbox";
import Employer from "../../components/employer";
import Partner from "../../components/partner";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import Location04 from "../../components/location/Location04";
import ReviewJob from "../../components/jobs/ReviewJob";
import dataLocation from "../../assets/fakeData/dataLocation";
import dataEm from "../../assets/fakeData/dataEmployers";
import dataPartner from "../../assets/fakeData/dataPartner";
import axios from 'axios';
import domain from "../../api/constent";
import TabJob from "../../components/jobs/TabJob";
import dataJobs from "../../assets/fakeData/dataJobs";
import { Box, Typography, CircularProgress } from '@mui/material';
import CardComp from "../../components/card-style/cardComp";

import { Link, useNavigate } from "react-router-dom";
import MetaCategory from "../../components/dropdown/MetaCategory";
import RoleComp from "../../components/Role/Role";
import EducationComp from "../../components/Education/EducationComp";
import LicensesAComp from "../../components/LicencesAndCertificate/LicensesAComp";
import SkillComp from "../../components/Skill/SkillComp";
import LanguageComp from "../../components/language/LanguageComp";

import Session from "../../api/session";
import FullPageShimmerLoading from '../../utils/FullPageShimmerLoading'; // Import shimmer effect component

ProfilePage.propTypes = {};
function ProfilePage(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate loading for 3 seconds
        setTimeout(() => setLoading(false), 3000);
    }, []);


    const [jobList, setDataInFeatured] = useState([]);
    const [error, setError] = useState(null);
    const [getPassValue, setPassValue] = useState([]);
    const [getPersonalDetails, setPersonalDetails] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const WOW = require("wowjs");
        window.wow = new WOW.WOW({
            live: false,
        });
        window.wow.init();
        fetchCheckingLoginPage();
        fetchInfo();
    }, []);

    const fetchCheckingLoginPage = async () => {
        if (Session.getChecking() != false) {
            fetchPersonalDetails();

        } else {
            // console.log("session is not exist");
            navigate("/login");
            // return false;
        }
    }
    const fetchInfo = async () => {
        try {
            setLoading(true);

            const resp = await axios.get(domain.Job_Posting_List_URL);
            console.log(resp.data);
            setDataInFeatured(resp.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching data');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const fetchPersonalDetails = async () => {
        try {
            setLoading(true);
            Session.getCSRF();
            const resp = await axios.post(domain.Personal_Details, {
                c_id: Session.getDataSessionStore().id,
                c_email: Session.getDataSessionStore().email,
            });
            console.log(resp.data);
            setPersonalDetails(resp.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching data');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const [toggle, setToggle] = useState({
        key: "",
        status: false,
    });
    const [isShowMobile, setShowMobile] = useState(false);

    const handleToggle = (key) => {
        if (toggle.key === key) {
            setToggle({
                status: false,
            });
        } else {
            setToggle({
                status: true,
                key,
            });
        }
    };

    const handleMobile = () => {
        const getMobile = document.querySelector(".menu-mobile-popup");
        setShowMobile(!isShowMobile);
        !isShowMobile
            ? getMobile.classList.add("modal-menu--open")
            : getMobile.classList.remove("modal-menu--open");
    };



    const [isShow, setShow] = useState(false);

    const handlePopup = () => {
        const getPopUp = document.querySelector(".wd-popup-job-apply");
        setShow(!isShow);
        !isShow
            ? getPopUp.classList.add("modal-menu--open")
            : getPopUp.classList.remove("modal-menu--open");
    };
    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>{error}</div>;

    // console.log(loading);
    // if (loading) {
    //     return (
    //         <Box
    //             sx={{
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 minHeight: '100vh',
    //                 bgcolor: 'background.default',
    //                 color: 'text.primary',
    //             }}
    //         >
    //             <CircularProgress color="primary" size={60} thickness={4} />
    //             <Typography variant="h6" sx={{ mt: 2 }}>
    //                 Loading...
    //             </Typography>
    //         </Box>
    //     );
    // }
    // if (error) return <Typography color="error">Error: {error}</Typography>;


    return (
        <>
            <Header1 clname="act1" handleMobile={handleMobile} />
            {/* <FullPageShimmerLoading loading={loading} /> */}

            <div className="card" >

                <CardComp tempJson={getPersonalDetails.data} />
                <div className="container pl-4">
                    <h5>Career History</h5>
                    <br />
                    <RoleComp />
                    <br />
                    <EducationComp />
                    <br />
                    <LicensesAComp />
                    <br />
                    <SkillComp />
                    <br />
                    <LanguageComp />
                </div>
            </div>
            {/* <Container>
                <Grid container spacing={4} mt={4}>
                    <Grid item xs={12} md={4}>
                        <Box className="card">
                            <CardComp valueJson={passValue} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8}>

                        <Box className="container pl-4">
                            <h4>Personal Profile Details</h4>
                            <small>Career History</small>
                            <br />
                            <RoleComp />
                            <br />
                            <EducationComp />
                            <br />
                            <LicensesAComp />
                            <br />
                            <SkillComp />
                        </Box>
                    </Grid>



                </Grid>
            </Container> */}
            <Footer />
            <Gotop />
        </>
    );
}

export default ProfilePage;
