import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    FormControlLabel,
    Checkbox,
    Snackbar,
    Alert,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Typography,
    Grid,
    Link,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';

import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";

import EditCreateModal from '../Role/DemoTesting';

import Session from '../../api/session';
import axios from '../../api/axios';
import domain from "../../api/constent";
import apiService from "../../api/apiService";

import Swal from 'sweetalert2';

export default function RoleComp() {
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isStillInRole, setIsStillInRole] = useState(false);
    const [description, setDescription] = useState('');
    // Consolidated form state (for both create and edit)
    const [formData, setFormData] = useState({
        jobTitle: '',
        companyName: '',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        isStillInRole: false,
        description: '',
    });

    const [editDetails, setEditDetails] = useState(null);
    const [errors, setErrors] = useState({
        jobTitle: false,
        companyName: false,
        startMonth: false,
        startYear: false,
        endMonth: false,
        endYear: false,
        isStillInRole: 0,
        description: false,
    });

    const handleCreateOpen = (item = null) => {
        setOpenCreate(true);
        if (item) {
            // If editing, set the form data to the item
            setFormData({
                jobTitle: item.job_title,
                companyName: item.company_name,
                startMonth: item.start_month,
                startYear: item.start_year,
                endMonth: item.end_month,
                endYear: item.end_year,
                isStillInRole: item.is_current,
                description: item.description,
            });
            setEditDetails(item);
        }
    };

    const handleCreateClose = () => {
        setOpenCreate(false);
        setFormData({
            jobTitle: '',
            companyName: '',
            startMonth: '',
            startYear: '',
            endMonth: '',
            endYear: '',
            isStillInRole: '',
            description: '',
        });
        setErrors({
            jobTitle: false,
            companyName: false,
            startMonth: false,
            startYear: false,
            endMonth: false,
            endYear: false,
            isStillInRole: 0,
            description: false,
        });
    };

    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSave = async () => {
        const newErrors = {
            jobTitle: !formData.jobTitle,
            companyName: !formData.companyName,
            startMonth: !formData.startMonth,
            startYear: !formData.startYear,
            endMonth: !formData.endMonth,
            endYear: !formData.endYear && !formData.isStillInRole,
            description: !formData.description,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).includes(true)) {
            return; // Don't submit if any field has an error
        }

        try {
            // Assuming you send the formData to API
            const response = await apiService.getDetailsRoleCreate(formData);
            console.log('Data saved:', response.data);
            setOpenSnackbar(true);
            handleCreateClose();
        } catch (error) {
            console.error('Error saving data:', error);
            alert('Failed to save data. Please try again later.');
        }
    };

    const fetchRoleList = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await apiService.getDetailsRoleList();
            setLists(data);
        } catch (err) {
            setError("Failed to fetch data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRoleList();
    }, []);

    return (
        <div>
            <List>
                {loading ? (
                    <ShimmerCategoryItems mode="light" />
                ) : (
                    lists.map((item) => (
                        <ListItem
                            key={item.id}
                            sx={{
                                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                mb: 2,
                                p: 2,
                                position: 'relative',
                            }}
                        >
                            <IconButton
                                onClick={() => handleCreateOpen(item)}
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    color: 'white',
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    borderRadius: '50%',
                                    padding: '8px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    },
                                }}
                            >
                                <EditIcon />
                            </IconButton>

                            <ListItemText
                                primary={<Typography variant="h6">{item.job_title}</Typography>}
                                secondary={
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="textSecondary">
                                                {item.company_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="textSecondary">
                                                {`${item.start_month} / ${item.start_year} - ${item.end_month ? item.end_month + '/' + item.end_year : 'Present'}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{item.description}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Link href="#" underline="hover" color="primary">
                                                Review this company
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                <b>More</b>
                                                {/* <IconButton onClick={handleToggle} size="small">
                                                    {openRole ? <ExpandLess /> : <ExpandMore />}
                                                </IconButton> */}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* <Collapse in={openRole} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <ListItem>
                                                        <ListItemText primary="• Key Responsibilities" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="• Managed project timelines and deliverables." />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="• Coordinated with cross-functional teams." />
                                                    </ListItem>
                                                </List>
                                            </Collapse> */}
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                    ))
                )}
            </List>
            <div className="pt-2">
                <Button size="small" variant="contained" onClick={handleCreateOpen}>
                    Add Role
                </Button>
            </div>

            {/* Modal Dialog */}
            <Dialog open={openCreate} onClose={handleCreateClose} fullWidth maxWidth="md">
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Add Role
                    <IconButton edge="end" color="inherit" onClick={handleCreateClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>  <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingBottom: 3 }}>
                    {/* Job Title */}
                    <TextField
                        label="Job Title"
                        variant="outlined"
                        fullWidth
                        value={formData.jobTitle}
                        onChange={(e) => handleChange('jobTitle', e.target.value)}
                        error={errors.jobTitle}
                        helperText={errors.jobTitle ? 'Job Title is required' : ''}
                        sx={{ marginBottom: 2, marginTop: 3 }}
                    />

                    {/* Company Name */}
                    <TextField
                        label="Company Name"
                        variant="outlined"
                        fullWidth
                        value={formData.companyName}
                        onChange={(e) => handleChange('companyName', e.target.value)}
                        error={errors.companyName}
                        helperText={errors.companyName ? 'Company Name is required' : ''}
                        sx={{ marginBottom: 2 }}
                    />

                    <div style={{ display: 'flex', gap: '10px' }}>
                        <FormControl fullWidth sx={{ marginBottom: 2 }} error={errors.startMonth}>
                            <InputLabel>Start Month</InputLabel>
                            <Select
                                value={formData.startMonth}
                                onChange={(e) => handleChange('startMonth', e.target.value)}
                                label="Start Month"
                            >
                                {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, idx) => (
                                    <MenuItem key={idx} value={month}>
                                        {month}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.startMonth && <div style={{ color: 'red' }}>Start Month is required</div>}
                        </FormControl>

                        <FormControl fullWidth sx={{ marginBottom: 2 }} error={errors.startYear}>
                            <InputLabel>Start Year</InputLabel>
                            <Select
                                value={formData.startYear}
                                onChange={(e) => handleChange('startYear', e.target.value)}
                                label="Start Year"
                            >
                                {['2020', '2021', '2022', '2023', '2024'].map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.startYear && <div style={{ color: 'red' }}>Start Year is required</div>}
                        </FormControl>
                    </div>

                    {/* End Date or Still in Role */}
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <FormControl fullWidth sx={{ marginBottom: 2 }} error={errors.endMonth}>
                            <InputLabel>End Month</InputLabel>
                            <Select
                                value={formData.endMonth}
                                onChange={(e) => handleChange('endMonth', e.target.value)}
                                label="End Month"
                                disabled={isStillInRole}
                            >
                                {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, idx) => (
                                    <MenuItem key={idx} value={month}>
                                        {month}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.endMonth && <div style={{ color: 'red' }}>End Month is required</div>}
                        </FormControl>

                        <FormControl fullWidth sx={{ marginBottom: 2 }} error={errors.endYear}>
                            <InputLabel>End Year</InputLabel>
                            <Select
                                value={formData.endYear}
                                onChange={(e) => handleChange('endYear', e.target.value)}
                                label="End Year"
                                disabled={isStillInRole}
                            >
                                {['2020', '2021', '2022', '2023', '2024'].map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.endYear && <div style={{ color: 'red' }}>End Year is required</div>}
                        </FormControl>

                        {/* Checkbox for "Still in Role" */}
                        <FormControl fullWidth sx={{ marginBottom: 2 }} error={errors.endYear}>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isStillInRole}
                                        onChange={(e) => setIsStillInRole(e.target.checked)}
                                    />
                                }
                                label="Still in Role"
                                sx={{ marginBottom: 2 }}
                            /> </FormControl>

                    </div>

                    {/* Description */}
                    <TextField
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={formData.description}
                        // onChange={(e) => handleChange('description', e.target.value)}
                        error={errors.description}
                        helperText={errors.description ? 'Description is required' : ''}
                        sx={{
                            marginBottom: 2,
                            padding: '10px',  // Adds some padding to make it feel less cramped
                            minHeight: '120px', // Ensures the field has a minimum height for better visibility
                            backgroundColor: '#fafafa',
                        }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button color="secondary" sx={{ backgroundColor: '#f44336', color: '#fff', '&:hover': { backgroundColor: '#e53935' } }}>
                        Cancel
                    </Button>
                    <Button color="primary" sx={{ backgroundColor: '#4caf50', color: '#fff', '&:hover': { backgroundColor: '#45a049' } }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
