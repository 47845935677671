import React, { useState, useEffect } from 'react';
import {
    TextField,
    Box,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Session from '../../api/session';
import axios from '../../api/axios';
import domain from "../../api/constent";
import { sweetAlert } from '../../utils/sweetAlert';

export default function CardComp({ tempJson = {} }) {
    const [open, setOpen] = useState(false);
    const [getJson, setTempJson] = useState({
        first_name: '',
        last_name: '',
        email: '',
        home_location: '',
        phone_no: '',
        country_code: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tempJson) {
            setTempJson({
                first_name: tempJson.first_name || '',
                last_name: tempJson.last_name || '',
                email: tempJson.email || '',
                home_location: tempJson.home_location || '',
                phone_no: tempJson.phone_no || '',
                country_code: tempJson.country_code || 'us'
            });
        }
    }, [tempJson]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeAndReset = () => {
        setTempJson({
            first_name: '',
            last_name: '',
            email: '',
            home_location: '',
            phone_no: '',
            country_code: ''
        });
        handleClose();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTempJson((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePhoneChange = (value, country) => {
        setTempJson((prevState) => ({
            ...prevState,
            phone_no: value,
            country_code: country?.dialCode || '1',
        }));
    };

    const handleUpdate = async () => {
        try {
            console.log(getJson);
            Session.getCSRF();
            setLoading(true);
            const userId = Session.getDataSessionStore().id;
            const resp = await axios.post(`${domain.Update_Personal_Details}/${userId}`, getJson);
            if (resp.status === 200) {
                console.log('User updated successfully:', resp.data);
                // closeAndReset();
                handleClose();
            }
            sweetAlert('Success!', 'Your Personal Details have been updated successfully.', 1); // Success

        } catch (error) {
            console.error('Error updating profile:', error);
            sweetAlert('Error!', 'There was an error updating your profile. Please try again.', 2); // Error
        } finally {
            setLoading(false);
        }
    };

    const valueJson = {
        name: `${getJson.first_name} ${getJson.last_name}`,
        gmail: getJson.email,
        phone: `+${getJson.country_code} ${getJson.phone_no}`,
        location: getJson.home_location,
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                    marginTop: '10px',
                    marginBottom: '10px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    flexDirection: 'column',
                    color: 'white',
                    padding: '20px',
                    borderRadius: '20px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    background: 'radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%)',
                    maxWidth: '1300px',
                }}
            >
                <IconButton onClick={handleOpen} sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    borderRadius: '50%',
                    padding: '8px',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}>
                    <EditIcon />
                </IconButton>

                <Typography variant="h5" component="div" gutterBottom>
                    {valueJson.name || 'Name not available'}
                </Typography>
                <Box display="flex" alignItems="center" mt={1}>
                    <LocationOnIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body2">{valueJson.location || 'Location not available'}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                    <CallIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body2">{valueJson.phone || 'Phone not available'}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                    <MailIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body2">{valueJson.gmail || 'Email not available'}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>

                    <Button size="small" variant="contained" onClick={handleOpen} >Edit</Button>
                </Box>
            </Box>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                {/* <DialogTitle>Edit Details</DialogTitle> */}
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                        <Typography variant="h6">Edit Personal Details for {valueJson.name}</Typography>
                        <Box display="flex" flexDirection="column" mt={2}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                value={getJson.first_name}
                                onChange={handleInputChange}
                                name="first_name"
                                size="small"
                                fullWidth
                            />
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                value={getJson.last_name}
                                onChange={handleInputChange}
                                name="last_name"
                                size="small"
                                fullWidth
                                sx={{ mt: 2 }}
                            />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Address Location"
                                multiline
                                value={getJson.home_location}
                                onChange={handleInputChange}
                                name="home_location"
                                maxRows={4}
                                fullWidth
                                size="small"
                            />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <PhoneInput
                                country={getJson.country_code || '+1'}
                                value={getJson.phone_no}
                                onChange={handlePhoneChange}
                                inputStyle={{
                                    width: '100%',
                                    height: '40px',
                                    fontSize: '16px',
                                }}
                                containerStyle={{
                                    width: '100%',
                                }}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdate} color="primary" disabled={loading}>
                        {loading ? 'Saving...' : 'Save Changes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
