//main domain
const Root_URL = process.env.REACT_APP_ROOT_URL;
const Main_URL = process.env.REACT_APP_DB_URL;
const JOB_FEATURED_URL = `${Main_URL}/job_featured`;
const Job_Category_URL = `${Main_URL}/job_category_list`;
const Job_Posting_List_URL = `${Main_URL}/job_posting_list`;
const Job_Keyword_Find_URL = `${Main_URL}/job-keyword`;
const Profile_Details = `${Main_URL}/profile_details`;

const Sanctum_CSRF = `${Root_URL}/sanctum/csrf-cookie`;
const CandidateRegister_URL = `${Main_URL}/new_registration`;
const Login_URL = `${Main_URL}/login`;
const Registration_URL = `${Main_URL}/register`;

// Profile Section
const Personal_Details = `${Main_URL}/profile_details`;
const Update_Personal_Details = `${Main_URL}/update_personal_details`;
// Career History
const Career_History_List = `${Main_URL}/career-history-list/`;
const Career_History_Create = `${Main_URL}/career-history-create`;
const Career_History_Edit = `${Main_URL}/career-history-update/`;
const Career_History_delete = `${Main_URL}/career-history-delete/`;

// Education
const Education_List = `${Main_URL}/candidate-education-list/`;
const Candidate_Education_Create = `${Main_URL}/candidate-education-create`;
const Candidate_Education_Edit = `${Main_URL}/candidate-education-update/`;
const Candidate_Education_delete = `${Main_URL}/candidate-education-delete/`;

// Licenses
const Licenses_List = `${Main_URL}/candidate-license-list/`;
const Candidate_Licenses_Create = `${Main_URL}/candidate-license-create`;
const Candidate_Licenses_Edit = `${Main_URL}/candidate-license-update/`;
const Candidate_Licenses_delete = `${Main_URL}/candidate-license-delete/`;

// Skills
const Skills_List = `${Main_URL}/candidate-skill-list/`;
const Candidate_Skill_Create = `${Main_URL}/candidate-skill-create`;
const Candidate_Skill_Edit = `${Main_URL}/candidate-skill-update/`;
const Candidate_Skill_delete = `${Main_URL}/candidate-skill-delete/`;

// Languages
const Languages_List = `${Main_URL}/candidate-language-list/`;

const domain = {
  JOB_FEATURED_URL,
  Profile_Details,
  Job_Category_URL,
  Login_URL,
  Sanctum_CSRF,
  CandidateRegister_URL,
  Registration_URL,
  Job_Posting_List_URL,
  Job_Keyword_Find_URL,
  Personal_Details,
  Update_Personal_Details,
  Career_History_List,
  Career_History_Create,
  Career_History_Edit,
  Career_History_delete,
  Education_List,
  Candidate_Education_Create,
  Candidate_Education_Edit,
  Candidate_Education_delete,
  Licenses_List,
  Candidate_Licenses_Create,
  Candidate_Licenses_Edit,
  Candidate_Licenses_delete,
  Skills_List,
  Candidate_Skill_Create,
  Candidate_Skill_Edit,
  Candidate_Skill_delete,
  Languages_List,
};
export default domain;
