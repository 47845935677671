import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Gallery from "../popup/Gallery";
import Video from "../popup/Video";
import Session from "../../api/session";
// Profile Component
import ProfileModel from "../candidates/CandidateProfile";
import { Modal, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// steps form
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextFields from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import cardStyles from '../../assets/css/card-style/card.css';

import axiosDom from "../../api/axios";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import domain from "../../api/constent";
import { sweetAlert } from '../../utils/sweetAlert';
import rootSession from "../../api/session";

const steps = [
  'Choose a Document',
  'Answer Employee Queries',
  'Update Profile',
  'Review and Submit'
];
TabJob.propTypes = {};

// file upload
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Set the width to 80% of the viewport width
  maxWidth: '600px', // Set a maximum width
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: {
    xs: '90%', // 90% width for extra-small screens
    sm: '80%', // 80% width for small screens
    md: '60%', // 60% width for medium screens
    lg: '50%', // 50% width for large screens
    xl: '40%'  // 40% width for extra-large screens
  },
  maxHeight: '70vh', // Limit modal height to 90% of viewport height
  overflowY: 'auto'  // Enable scrolling if content overflows
};

function TabJob(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);

  function handleRedirect(data) {
    // console.log("SDFDS", data);
    if (Session.getChecking()) {
      handleOpen(data);
    } else {
      // If the condition is false, redirect to the login page
      window.location.href = "/login";
    }
  }

  const [modalContent, setModalContent] = useState({});
  const handleOpen = (value) => {
    // console.log("Opening model", value);

    setModalContent(value);
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({
    usernameOrEmail: "",
    password: ""
  });


  const [errors, setErrors] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [err, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleSubmit = () => {

  };
  // step1
  const [contact, setContact] = useState({
    name: 'Jayant Nishad',
    location: 'Melbourne, Melbourne VIC',
    phone: '+91 123456789',
    email: 'abcd@gmail.com',
  });

  var inputStyle = {
    height: '0px',
    padding: '0px',

  }
  const [phone, setPhone] = useState('');
  const [selectedResume, setSelectedResume] = useState(null);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  // const handlingEdit = () => {
  //   console.log("personal detials");
  //   ChildModal();
  // };
  // step2
  const [workRight, setWorkRight] = useState('');
  const [projectManagementExperience, setProjectManagementExperience] = useState(0);
  const [projectManagerExperience, setProjectManagerExperience] = useState(0);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    try {

      console.log(file);

      // const response = await axios.post('http://your-laravel-app-url/api/upload', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // });
      // setMessage(response.data.message);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('File upload failed');
    }
  };
  const handleChange = (value) => {
    setPhone(value);
  };
  const handleResume = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);

    setSelectedResume(selectedValue);
  };

  const handleWorkRightChange = (event) => {
    setWorkRight(event.target.value);
  };

  const handleProjectManagementExperienceChange = (event) => {
    setProjectManagementExperience(parseInt(event.target.value, 10));
  };

  const handleProjectManagerExperienceChange = (event) => {
    setProjectManagerExperience(parseInt(event.target.value, 10));
  };

  // step 3 
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [startDate, setStartDate] = useState({ month: '', year: '' });
  const [endDate, setEndDate] = useState({ month: '', year: '' });
  const [description, setDescription] = useState('');
  const [isNewToWorkforce, setIsNewToWorkforce] = useState(false);
  const [isStillInRole, setIsStillInRole] = useState(false);

  const handleJobTitleChange = (event) => {
    setJobTitle(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleStartDateChange = (event) => {
    const { name, value } = event.target;
    setStartDate({ ...startDate, [name]: value });
  };

  const handleEndDateChange = (event) => {
    const { name, value } = event.target;
    setEndDate({ ...endDate, [name]: value });
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleNewToWorkforceChange = () => {
    setIsNewToWorkforce(!isNewToWorkforce);
  };

  const handleStillInRoleChange = () => {
    setIsStillInRole(!isStillInRole);
  };

  const handleDSubmit = () => {
    // Submit form data to backend
    console.log({
      jobTitle,
      companyName,
      startDate,
      endDate,
      description,
      isNewToWorkforce,
      isStillInRole,
    });
  };
  // Step 4
  const [resumeIncluded, setResumeIncluded] = useState(false);
  const [coverLetterIncluded, setCoverLetterIncluded] = useState(false);
  const [roleCount, setRoleCount] = useState(1);
  const [qualificationCount, setQualificationCount] = useState(0);
  const [credentialCount, setCredentialCount] = useState(0);
  const [skillCount, setSkillCount] = useState(0);

  const handleResumeChange = () => {
    setResumeIncluded(!resumeIncluded);
  };

  const handleCoverLetterChange = () => {
    setCoverLetterIncluded(!coverLetterIncluded);
  };

  const handleRoleCountChange = (event) => {
    setRoleCount(parseInt(event.target.value, 10));
  };

  const handleQualificationCountChange = (event) => {
    setQualificationCount(parseInt(event.target.value, 10));
  };

  const handleCredentialCountChange = (event) => {
    setCredentialCount(parseInt(event.target.value, 10));
  };

  const handleSkillCountChange = (event) => {
    setSkillCount(parseInt(event.target.value, 10));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <div class="pt-3">
          <div className="row ">
            {/* <div class="row p-3">

              <div className="col-md-6">

                <TextFields
                  label="First Name"
                  variant="outlined"
                  style={{ inputStyle }}
                  id="outlined-size-small"
                  // defaultValue="Small"
                  size="small"
                />
              </div>


              <div className="col-md-6">
                <TextFields
                  label="Last Name"
                  variant="outlined"
                  style={{ inputStyle }}
                  id="outlined-size-small"
                  // defaultValue="Small"
                  size="small"
                />
              </div>

            </div>
            <div class="row p-3">


              <div className="col-md-12">
                <TextFields
                  id="outlined-multiline-flexible"
                  label="Address Location"
                  multiline
                  style={{ inputStyle }}
                  maxRows={4}
                />

              </div>
            </div>
            <div class="row p-3">
              <div className="col-md-6">
                <PhoneInput
                  country={'us'}
                  value={phone}
                  onChange={handleChange}
                  inputStyle={{
                    width: '100%',
                    height: '40px',
                    fontSize: '16px',
                  }}
                  containerStyle={{
                    width: '100%',
                  }}
                />
              </div>

            </div>  */}
            <div className="contact-card" style={{ cardStyles }}>
              <div >
                <h4 style={{ color: 'white' }}>{contact.name}</h4>
              </div>
              <div className="card-content">
                <ul>
                  <li>
                    <LocationOnIcon />
                    {/* <i className="fas fa-map-marker-alt"></i> */}
                    {contact.location}
                  </li>
                  <li>
                    <CallIcon />
                    {/* <i className="fas fa-phone"></i> */}
                    {contact.phone}
                  </li>
                  <li>
                    <MailIcon />
                    {/* <i className="fas fa-envelope"></i> */}
                    {contact.email}
                  </li>
                </ul>
                {/* <Button size="small" variant="contained" onClick={handleOpen}>Edit</Button> */}
                <ChildModal />
              </div>
            </div>
            <div class="row p-3">
              <p>Resume</p>
              <ol>
                {/* /checked={selectedResume === "1"} */}
                <li><input type="radio" name="resume" onClick={handleResume} value="1" />
                  &nbsp;&nbsp;  Upload a resume
                  {selectedResume === "1" && (
                    <>
                      <br />
                      <form onSubmit={handleUpload}>

                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload files
                          <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                            // onChange={(event) => console.log(event.target.files)}
                            multiple
                          />
                        </Button>
                        <button type="submit">Upload File</button>

                        {message && <p>{message}</p>}

                      </form>
                    </>
                  )}
                </li>  <li><input type="radio" name="resume" onClick={handleResume} value="2" />
                  <br />
                  &nbsp;&nbsp;    Select a resume
                  {selectedResume === "2" && (
                    <>

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Resume </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // value={age}
                          label="Age"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  )}
                </li>  <li><input type="radio" name="resume" onClick={handleResume} value="3" />
                  &nbsp;&nbsp;  Don't include a resume
                </li>
              </ol>

            </div>
            <div class="row p-3">
              <p>Conver Letter</p>
              <ol>
                <li><input type="radio" name="resume" />
                  &nbsp;&nbsp;   Upload a conver letter
                </li>  <li><input type="radio" name="resume" />
                  &nbsp;&nbsp;   Select a conver letter
                </li>  <li><input type="radio" name="resume" />
                  &nbsp;&nbsp;  Don't include a conver letter
                </li>
              </ol>
            </div>
          </div>
        </div>;
      case 1:
        return <div class=" pt-3">
          <strong>Please answer the following queries:</strong>
          <div>
            <label htmlFor="work-right">Which of the following statements best describes your right to work in Australia?</label>
            <select id="work-right" value={workRight} onChange={handleWorkRightChange}>
              <option value="">Select an option</option>
              <option value="permanent-resident-nz-citizen">I'm a permanent resident and/or NZ citizen</option>
              {/* Add other options as needed */}
            </select>
          </div>
          <div>
            <label htmlFor="project-manager-experience">How many years' experience do you have as a project manager?</label>
            <input
              type="number"
              id="project-manager-experience"
              value={projectManagerExperience}
              onChange={handleProjectManagerExperienceChange}
            />
          </div>
          <div>
            <label htmlFor="project-management-experience">How many years of project management experience do you have?</label>
            <input
              type="number"
              id="project-management-experience"
              value={projectManagementExperience}
              onChange={handleProjectManagementExperienceChange}
            />
          </div>
        </div>;

      case 2:
        return <div class="pt-3"> {/* Content for updating profile */}
          <div>
            <strong>Career History</strong>
            <br />
            <label htmlFor="new-to-workforce">
              <input
                type="checkbox"
                id="new-to-workforce"
                checked={isNewToWorkforce}
                onChange={handleNewToWorkforceChange}
              />&nbsp;&nbsp;
              I am new to the workforce
            </label>
            <div>
              <label htmlFor="job-title ">Job Title:</label>
              <input
                type="text"
                id="job-title"
                value={jobTitle}
                onChange={handleJobTitleChange}
              />
            </div>
            <div>
              <label htmlFor="company-name">Company Name:</label>
              <input
                type="text"
                id="company-name"
                value={companyName}
                onChange={handleCompanyNameChange}
              />
            </div>
            <div>
              <label>Start Date:</label>
              <input
                type="number"
                name="month"
                placeholder="Month"
                value={startDate.month}
                onChange={handleStartDateChange}
              />
              <input
                type="number"
                name="year"
                placeholder="Year"
                value={startDate.year}
                onChange={handleStartDateChange}
              />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isStillInRole}
                  onChange={handleStillInRoleChange}
                />
                Still in role
              </label>
              {!isStillInRole && (
                <div>
                  <label>End Date:</label>
                  <input
                    type="number"
                    name="month"
                    placeholder="Month"
                    value={endDate.month}
                    onChange={handleEndDateChange}
                  />
                  <input
                    type="number"
                    name="year"
                    placeholder="Year"
                    value={endDate.year}
                    onChange={handleEndDateChange}
                  />
                </div>
              )}
            </div>
            <div>
              <label htmlFor="description">Description:</label>
              <textarea
                id="description"
                value={description}
                onChange={handleDescriptionChange}
              />
            </div>
            {/* <button onClick={handleDSubmit}>Submit</button> */}
            <br />  <div class="pt-2">

              <Button size="small" variant="contained">Add role</Button>
            </div>

          </div>
          <div class="pt-2">
            <strong>Education</strong>
            <p  >Tell Employers about your education</p>

            <Button size="small" variant="contained">Add education</Button>
          </div>  <div class="pt-2" >
            <strong >Licences & certifications
            </strong>
            <p>Showcase your professional credentials. Add your relevant licences, certificates, memberships and accreditations here.</p>

            <Button size="small" variant="contained">Add Licences & certifications  </Button>
          </div>
          <div class="pt-2" >
            <strong>Skills</strong>
            <p>Let employers know how valuable you can be to them.</p>

            <Button size="small" variant="contained">Add skills</Button>
          </div>
        </div>;

      case 3:
        return <div class="pt-2"> {/* Content for reviewing and submitting */}
          <strong>Review and Submit</strong>
          <p>Please review your information and submit:</p>
          <div className="contact-card" style={{ cardStyles }}>
            <div >
              <h4 style={{ color: 'white' }}>{contact.name}</h4>
            </div>
            <div className="card-content">
              <ul>
                <li>
                  <i className="fas fa-map-marker-alt"></i>
                  {contact.location}
                </li>
                <li>
                  <i className="fas fa-phone"></i>
                  {contact.phone}
                </li>
                <li>
                  <i className="fas fa-envelope"></i>
                  {contact.email}
                </li>
              </ul>
              <Button size="small" variant="contained">Edit</Button>
            </div>
          </div>

          <div className="profile-content">
            <strong>Documents included</strong>
            <div className="document-section">
              <strong>Resumé</strong>
              <p>{resumeIncluded ? 'Resume included' : 'No resume included'}</p>
              <i className="fas fa-edit "></i>
              <input type="checkbox" checked={resumeIncluded} onChange={handleResumeChange} />
            </div>
            <div className="document-section">
              <strong>Cover Letter</strong>
              <p>{coverLetterIncluded ? 'Cover letter included' : 'No cover letter included'}</p>
              <i className="fas fa-edit"></i>
              <input type="checkbox" checked={coverLetterIncluded} onChange={handleCoverLetterChange} />
            </div>
            <div className="role-section">
              <strong>Roles</strong>
              <input type="number" value={roleCount} onChange={handleRoleCountChange} min="1" />
            </div>
            <div className="qualification-section">
              <strong>Qualifications</strong>
              <input type="number" value={qualificationCount} onChange={handleQualificationCountChange} min="0" />
            </div>
            <div className="credential-section">
              <strong>Credentials</strong>
              <input type="number" value={credentialCount} onChange={handleCredentialCountChange} min="0" />
            </div>
            <div className="skill-section">
              <strong>Skills</strong>
              <input type="number" value={skillCount} onChange={handleSkillCountChange} min="0" />
            </div>
          </div>

        </div >;

      default:
        return <div>Unknown step</div>;
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };



  return (
    <section className="tab-section">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: '80%', maxWidth: '800px' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">Applying for </Typography>
          <h4>{modalContent.title}</h4>
          <p>{modalContent.cate}</p>
          <Typography id="modal-modal-title" variant="h6" component="h4">view job details </Typography>

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   );
              // }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="contained" onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {renderStepContent(activeStep)} {/* Render the content for the current step */}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <Button variant="contained" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Modal>
      <div className="tf-container">
        <Tabs className="row tf-tab">
          <div className="col-xl-5 col-lg-5 col-md-12">
            <div className="group-job-sidebar">
              <TabList className="list-job menu-tab">
                {data.map((idx) => (
                  <Tab key={idx.id} className="features-job mg-bt-0 ct-tab">
                    <div className="job-archive-header">
                      <div className="inner-box">
                        <div className="logo-company">
                          <img src={idx.img} alt="Jobtex" />
                        </div>
                        <div className="box-content">
                          <h4>
                            <Link to="/Jobsingle_v1">{idx.cate}</Link>
                          </h4>
                          <h3>
                            <Link to="/Jobsingle_v1">{idx.title}</Link>&nbsp;
                            <span className="icon-bolt"></span>
                          </h3>
                          <ul>
                            <li>
                              <span className="icon-map-pin"></span>
                              {idx.map}
                            </li>
                            <li>
                              <span className="icon-calendar"></span>
                              {idx.time}
                            </li>
                          </ul>
                          <span className="icon-heart"></span>
                        </div>
                      </div>
                    </div>
                    <div className="job-archive-footer">
                      <div className="job-footer-left">
                        <ul className="job-tag">
                          <li>
                            <Link to="#">    {idx.jobs1}</Link>{" "}
                          </li>
                          <li>
                            <Link to="#">    {idx.jobs2}</Link>{" "}
                          </li>
                        </ul>
                        <div className="star">
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                        </div>
                      </div>
                      <div className="job-footer-right">
                        <div className="price">
                          <span className="icon-dolar1"></span>
                          <p>
                            {idx.price} <span className="year">/year</span>
                          </p>
                        </div>
                        <p className="days">{idx.apply}</p>
                      </div>
                    </div>
                  </Tab>
                ))}

                <Link to="#" className="btn-load">
                  Load more Job Results (3719)
                </Link>
              </TabList>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-12" id="zIndex">
            {data.map((idx) => (
              <TabPanel className="content-tab sticky-home2 po-sticky add-mgt">
                <article className={`job-article ${idx.stt}`}>
                  <div className="top-content">
                    <div className="features-job style-2 stc-apply">
                      <div className="job-archive-header">
                        <div className="inner-box">
                          <div className="logo-company">
                            <img src={idx.img} alt="Jobtex" />
                          </div>
                          <div className="box-content">
                            <h4>
                              <Link to="/Jobsingle_v1">
                                {idx.cate}
                              </Link>
                            </h4>
                            <h3>
                              <Link to="/Jobsingle_v1">
                                {idx.title}
                              </Link>
                              &nbsp;
                              <span className="icon-bolt"></span>
                            </h3>
                            <ul>
                              <li>
                                <span className="icon-map-pin"></span>
                                {idx.map}
                              </li>
                              <li>
                                <span className="icon-calendar"></span>
                                {idx.time}

                              </li>
                            </ul>
                            <div className="button-readmore">
                              <span className="icon-heart"></span>
                              <Link
                                className="btn-apply btn-popup"
                                onClick={() => handleRedirect({ title: idx.title, cate: idx.cate })}

                              >
                                <span className="icon-send" ></span>
                                Apply Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="job-archive-footer">
                        <div className="job-footer-left">
                          <ul className="job-tag">
                            <li>
                              <Link to="#"> {idx.jobs1}
                              </Link>
                            </li>
                            <li>
                              <Link to="#">  {idx.jobs2}
                              </Link>
                            </li>
                          </ul>
                          <div className="star">
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                          </div>
                        </div>
                        <div className="job-footer-right">
                          <div className="price">
                            <span className="icon-dollar"></span>
                            <p>
                              {idx.price}{" "}
                              <span className="year">/year</span>
                            </p>
                          </div>
                          <p className="days">{idx.apply}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inner-content home2">
                    <h5>Full Job Description</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart, world-class
                      campaigns across a variety of mediums?
                    </p>
                    <p className="mg-19">
                      Deloitte's Green Dot Agency is looking to add a Lead User
                      Experience Designer to our experience design team. We want
                      a passionate creative who's inspired by new trends and
                      emerging technologies, and is able to integrate them into
                      memorable user experiences. A problem solver who is
                      entrepreneurial, collaborative, hungry, and humble; can
                      deliver beautifully designed, leading-edge experiences
                      under tight deadlines; and who has demonstrated proven
                      expertise.
                    </p>
                    <h6>The Work You'll Do:</h6>
                    <ul className="list-dot">
                      <li>
                        Support the Creative Directors and Associate Creative
                        Directors of experience design to concept and oversee
                        the production of bold, innovative, award-winning
                        campaigns and digital experiences.
                      </li>
                      <li>
                        Make strategic and tactical UX decisions related to
                        design and usability as well as features and functions.
                      </li>
                      <li>
                        Creates low- and high-fidelity wireframes that represent
                        a user's joGurney.
                      </li>
                      <li>
                        Effectively pitch wireframes to and solutions to
                        stakeholders. You'll be the greatest advocate for our
                        work, but you'll also listen and internalize feedback so
                        that we can come back with creative that exceeds
                        expectations.
                      </li>
                    </ul>
                    <h6>What you'll bring:</h6>
                    <ul className="list-dot mg-bt-15">
                      <li>
                        Passion for Human-Centered Design-a drive to make
                        interactive technology better for people.
                      </li>
                      <li>Thorough knowledge of UX/UI best practices.</li>
                      <li>
                        Understanding of brand identity and working within a
                        defined design system as well as contributing to it.
                      </li>
                      <li>
                        A mastery of craft. You dream about color, typography,
                        and interaction design every day. You are proficient
                        using tools like Figma and Adobe XD. You can efficiently
                        use your skill set to develop new designs within
                        existing and new visual systems and design languages.
                      </li>
                      <li>
                        A portfolio which highlights strong understanding of UX
                        design including but not limited to: user flows, IA, and
                        translating customer research, analytics, and insights
                        into wireframes and high-fidelity designs.
                      </li>
                      <li>
                        Possess problem-solving skills, an investigative
                        mentality, and a proactive nature-committed to
                        delivering solutions.
                      </li>
                      <li>Possess problem-solving skills</li>
                    </ul>
                    <h6>Qualifications:</h6>
                    <ul className="list-dot mg-bt-15">
                      <li>
                        Bachelor's degree preferred, or equivalent experience.
                      </li>
                      <li>
                        At least 5-8 years of experience with UX and UI design.
                      </li>
                      <li>
                        2 years of experience with design thinking or similar
                        framework that focuses on defining users' needs early.
                      </li>
                      <li>
                        Strong portfolio showing expert concept, layout, and
                        typographic skills, as well as creativity and ability to
                        adhere to brand standards.
                      </li>
                      <li>
                        Expertise in Figma, Adobe Creative Cloud suite,
                        Microsoft suite.
                      </li>
                      <li>
                        Ability to collaborate well with cross-disciplinary
                        agency team and stakeholders at all levels.
                      </li>
                      <li>
                        Forever learning: Relentless desire to learn and
                        leverage the latest web technologies.
                      </li>
                      <li>
                        Detail-oriented: You must be highly organized, be able
                        to multi-task, and meet tight deadlines.
                      </li>
                      <li>
                        Independence: The ability to make things happen with
                        limited direction. Excellent proactive attitude,
                        take-charge personality, and "can-do" demeanor.
                      </li>
                      <li>
                        Proficiency with Front-End UI technologies a bonus but
                        not necessary (such as HTML, CSS, JavaScript).
                      </li>
                    </ul>
                    <p>
                      For individuals assigned and/or hired to work in Colorado
                      or Nevada, Deloitte is required by law to include a
                      reasonable estimate of the compensation range for this
                      role. This compensation range is specific to the State of
                      Colorado and the State of Nevada and takes into account
                      the wide range of factors that are considered in making
                      compensation decisions including but not limited to skill
                      sets; experience and training; licensure and
                      certifications; and other business and organizational
                      needs. The disclosed range estimate has not been adjusted
                      for the applicable geographic differential associated with
                      the location at which the position may be filled. At
                      Deloitte, it is not typical for an individual to be hired
                      at or near the top of the range for their role and
                      compensation decisions are dependent on the facts and
                      circumstances of each case. A reasonable estimate of the
                      current range is $86425- $177470.
                    </p>
                    <p>
                      You may also be eligible to participate in a discretionary
                      annual incentive program, subject to the rules governing
                      the program, whereby an award, if any, depends on various
                      factors, including, without limitation, individual and
                      organizational performance.
                    </p>
                    <div className="post-navigation d-flex aln-center">
                      <div className="wd-social d-flex aln-center">
                        <span>Social Profiles:</span>
                        <ul className="list-social d-flex aln-center">
                          <li>
                            <Link to="#">
                              <i className="icon-facebook"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-linkedin2"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-twitter"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-pinterest"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-instagram1"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-youtube"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <Link to="#" className="frag-btn">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H13C13.1857 0 13.3678 0.0517147 13.5257 0.149349C13.6837 0.246984 13.8114 0.386681 13.8944 0.552786C13.9775 0.718892 14.0126 0.904844 13.996 1.08981C13.9793 1.27477 13.9114 1.45143 13.8 1.6L11.25 5L13.8 8.4C13.9114 8.54857 13.9793 8.72523 13.996 8.91019C14.0126 9.09516 13.9775 9.28111 13.8944 9.44721C13.8114 9.61332 13.6837 9.75302 13.5257 9.85065C13.3678 9.94829 13.1857 10 13 10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11V14C2 14.2652 1.89464 14.5196 1.70711 14.7071C1.51957 14.8946 1.26522 15 1 15C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V3Z"
                            fill="#64666C"
                          />
                        </svg>
                        Report job
                      </Link>
                    </div>
                    <div className="video-thumb">
                      <div className="content-tab2">
                        <div className="inner">
                          <div className="thumb">
                            <img
                              src={require("../../assets/images/review/thumbv3.jpg")}
                              alt="images"
                            />
                            <Video />
                          </div>
                        </div>
                      </div>
                      <Gallery />
                    </div>

                    <div className="related-job">
                      <h6>Related Jobs</h6>
                      <div className="features-job mg-bt-0">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img
                                src={require("../../assets/images/logo-company/cty2.png")}
                                alt="jobtex"
                              />
                            </div>
                            <div className="box-content">
                              <h4>
                                <Link to="/Jobsingle_v1">
                                  Tamari Law Group LLC
                                </Link>
                              </h4>
                              <h3>
                                <Link to="/Jobsingle_v1">
                                  HR Administration
                                </Link>
                                <span className="icon-bolt"></span>
                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  Las Vegas, NV 89107, USA
                                </li>
                                <li>
                                  <span className="icon-calendar"></span>2 days
                                  ago
                                </li>
                              </ul>
                              <span className="icon-heart"></span>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <ul className="job-tag">
                              <li>
                                <Link to="#"> Part-time</Link>
                              </li>
                              <li>
                                <Link to="$"> Remote</Link>
                              </li>
                            </ul>
                            <div className="star">
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                            </div>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <span className="icon-dolar1"></span>
                              <p>
                                $83,000 - $110,000{" "}
                                <span className="year">/year</span>
                              </p>
                            </div>
                            <p className="days">24 days left to apply</p>
                          </div>
                        </div>
                      </div>
                      <div className="features-job mg-bt-0">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img
                                src={require("../../assets/images/logo-company/cty7.png")}
                                alt="jobtex"
                              />
                            </div>
                            <div className="box-content">
                              <h4>
                                <Link to="/Jobsingle_v1">
                                  Tamari Law Group LLC
                                </Link>
                              </h4>
                              <h3>
                                <Link to="/Jobsingle_v1">
                                  HR Administration
                                </Link>
                                <span className="icon-bolt"></span>
                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  Las Vegas, NV 89107, USA
                                </li>
                                <li>
                                  <span className="icon-calendar"></span>2 days
                                  ago
                                </li>
                              </ul>
                              <span className="icon-heart"></span>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <ul className="job-tag">
                              <li>
                                <Link to="#"> Part-time</Link>
                              </li>
                              <li>
                                <Link to="#"> Remote</Link>
                              </li>
                            </ul>
                            <div className="star">
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                            </div>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <span className="icon-dolar1"></span>
                              <p>
                                $83,000 - $110,000{" "}
                                <span className="year">/year</span>
                              </p>
                            </div>
                            <p className="days">24 days left to apply</p>
                          </div>
                        </div>
                      </div>
                      <div className="features-job mg-bt-0">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img
                                src={require("../../assets/images/logo-company/cty8.png")}
                                alt="jobtex"
                              />
                            </div>
                            <div className="box-content">
                              <h4>
                                <Link to="/Jobsingle_v1">
                                  Tamari Law Group LLC
                                </Link>
                              </h4>
                              <h3>
                                <Link to="/Jobsingle_v1">
                                  HR Administration
                                </Link>
                                <span className="icon-bolt"></span>
                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  Las Vegas, NV 89107, USA
                                </li>
                                <li>
                                  <span className="icon-calendar"></span>2 days
                                  ago
                                </li>
                              </ul>
                              <span className="icon-heart"></span>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <ul className="job-tag">
                              <li>
                                <Link to="#"> Part-time</Link>
                              </li>
                              <li>
                                <Link to="#"> Remote</Link>
                              </li>
                            </ul>
                            <div className="star">
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                            </div>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <span className="icon-dolar1"></span>
                              <p>
                                $83,000 - $110,000{" "}
                                <span className="year">/year</span>
                              </p>
                            </div>
                            <p className="days">24 days left to apply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </section>
  );
}

function ChildModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  var inputStyle = {
    height: '0px',
    padding: '0px',

  }
  const [phone, setPhone] = useState('');
  const [selectedResume, setSelectedResume] = useState(null);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleChange = (value) => {
    setPhone(value);
  };
  const handleResume = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);

    setSelectedResume(selectedValue);
  };

  return (
    <React.Fragment>
      <Button size="small" variant="contained" onClick={handleOpen}>Edit</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: '80%', maxWidth: '800px', position: 'relative' }}>
          {/* Close icon on the top-right */}
          <Typography variant="h4" component="h2">
            Candidate Personal Details
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{
              // color:'red',
              // backgroundColor: 'white',
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Content */}
          {/* <Button size="small" variant="contained" onClick={handleClose} style={{ marginBottom: '16px' }}>Close</Button> */}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextFields
                label="First Name"
                variant="outlined"
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFields
                label="Last Name"
                variant="outlined"
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFields
                id="outlined-multiline-flexible"
                label="Address Location"
                multiline
                fullWidth
                maxRows={4}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                country={'us'}
                placeholder="Enter Phone no."
                value={phone}
                onChange={handleChange}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  fontSize: '16px',
                }}
                containerStyle={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Button size="small" variant="contained" className="mt-3">Save</Button>

        </Box>
      </Modal>
    </React.Fragment >
  );
}
export default TabJob;
