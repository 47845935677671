import Swal from "sweetalert2";

export const sweetAlert = (title, text, type = 1, timer = 2000) => {
  let icon = "error"; // Default icon for error

  if (type === 1) {
    icon = "success"; // Success
  } else if (type === 2) {
    icon = "error"; // Error
  } else if (type === 3) {
    icon = "warning"; // Warning
  }

  Swal.fire({
    title,
    text,
    icon,
    position: "center",
    showConfirmButton: false,
    timer,
    toast: true, 
  });
};
