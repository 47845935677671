import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
    FormControlLabel,
    Checkbox,
    Snackbar,
    Alert,
    Box,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from '../../api/axios';

import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";

import domain from "../../api/constent";
import Session from '../../api/session';

// Main Component
export default function EducationComp() {
    const [openDialog, setOpenDialog] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // Flag to track if editing
    const [formData, setFormData] = useState({
        course: '',
        institution: '',
        qualificationComplete: false,
        finishedYear: '',
        courseHighlights: ''
    });
    const [errors, setErrors] = useState({
        course: '',
        institution: '',
        courseHighlights: ''
    });
    const [lists, setLists] = useState([]);
    const [loading, setLoading] = useState(true);

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 20 }, (_, index) => currentYear - index);

    useEffect(() => {
        const userId = Session.getDataSessionStore().id;
        const userEmail = Session.getDataSessionStore().email;

        axios.get(`${domain.Education_List}${userId}/${userEmail}`)
            .then(response => {
                setLists(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const handleChange = (field, value) => {
        setFormData(prevData => ({
            ...prevData,
            [field]: value
        }));
        if (value) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [field]: ''
            }));
        }
    };

    const handleOpenDialog = (data = null) => {
        if (data) {
            setFormData({
                ...data,
                qualificationComplete: data.qualification_complete === 1,
            });
            setIsEditMode(true);
        } else {
            setFormData({
                course: '',
                institution: '',
                qualificationComplete: false,
                finishedYear: '',
                courseHighlights: ''
            });
            setIsEditMode(false);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIsEditMode(false);
    };

    const handleSave = () => {
        const newErrors = {};
        if (!formData.course) newErrors.course = 'Course is required';
        if (!formData.institution) newErrors.institution = 'Institution is required';
        if (!formData.courseHighlights) newErrors.courseHighlights = 'Course Highlights are required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            if (isEditMode) {
                // Handle the edit operation
                axios.put(`${domain.Candidate_Education_Edit}${formData.id}`, formData)
                    .then(response => {
                        console.log('Updated Education:', response.data);
                        setOpenDialog(false);
                        // Optionally update lists state
                    })
                    .catch(error => {
                        console.error('Error updating education:', error);
                    });
            } else {
                // Handle the create operation
                axios.post(domain.Candidate_Education_Create, formData)
                    .then(response => {
                        console.log('Added Education:', response.data);
                        setOpenDialog(false);
                        // Optionally update lists state
                    })
                    .catch(error => {
                        console.error('Error adding education:', error);
                    });
            }
        }
    };

    const styles = {
        card: {
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '16px',
            marginTop: '5px',
            maxWidth: '400px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        course: {
            fontSize: '18px',
            fontWeight: 'bold',
            margin: 0,
        },
        editButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
        },
        verifyLink: {
            color: '#007bff',
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: '500',
        },
        institution: {
            fontSize: '16px',
            color: '#333',
            marginTop: '8px',
        },
        finishedYear: {
            fontSize: '14px',
            color: '#666',
            marginTop: '4px',
        },
        highlights: {
            fontSize: '14px',
            color: '#666',
            marginTop: '4px',
        },
    };

    return (
        <div>
            <strong>Education</strong>
            <p>Tell Employers about your education</p>
            {loading ? (
                <ShimmerCategoryItems mode="light" /> // Show shimmer effect while loading
            ) : (
                lists.map((item) => (
                    <div style={styles.card} key={item.id}>
                        <div style={styles.header}>
                            <h3 style={styles.course}>{item.course}</h3>
                            <button
                                style={styles.editButton}
                                onClick={() => handleOpenDialog(item)}
                                title="Edit"
                            >
                                ✏️
                            </button>
                        </div>
                        <a href="#" style={styles.verifyLink}>{item.institution}</a>
                        <p style={styles.institution}>{item.institution}</p>
                        <p style={styles.finishedYear}>Finished {item.finished_year}</p>
                        <p style={styles.highlights}>{item.course_highlights}</p>
                    </div>
                ))
            )}
            <div className="pt-2">
                <Button size="small" variant="contained" onClick={() => handleOpenDialog()}>
                    Add Education
                </Button>
            </div>

            {/* Education Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <strong>{isEditMode ? 'Edit Education' : 'Add Education'}</strong>
                        <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Course */}
                        <Grid item xs={12}>
                            <TextField
                                label="Course or Qualification"
                                variant="outlined"
                                fullWidth
                                value={formData.course}
                                onChange={(e) => handleChange('course', e.target.value)}
                                error={!!errors.course}
                                helperText={errors.course}
                                sx={{ marginTop: 3 }}
                            />
                        </Grid>

                        {/* Institution */}
                        <Grid item xs={12}>
                            <TextField
                                label="Institution"
                                variant="outlined"
                                fullWidth
                                value={formData.institution}
                                onChange={(e) => handleChange('institution', e.target.value)}
                                error={!!errors.institution}
                                helperText={errors.institution}
                            />
                        </Grid>

                        {/* Qualification Complete (Checkbox) */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.qualificationComplete}
                                        onChange={(e) => handleChange('qualificationComplete', e.target.checked)}
                                    />
                                }
                                label="Qualification Complete"
                            />
                        </Grid>

                        {/* Finished Year Dropdown */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Finished Year</InputLabel>
                                <Select
                                    value={formData.finishedYear}
                                    onChange={(e) => handleChange('finishedYear', e.target.value)}
                                    label="Finished Year"
                                    error={!!errors.finishedYear}
                                >
                                    {years.map(year => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Course Highlights */}
                        <Grid item xs={12}>
                            <TextField
                                label="Course Highlights"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                value={formData.courseHighlights}
                                onChange={(e) => handleChange('courseHighlights', e.target.value)}
                                error={!!errors.courseHighlights}
                                helperText={errors.courseHighlights}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
