import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "../../api/axios";
import domain from "../../api/constent";
import Swal from 'sweetalert2';
function SignUp() {

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    roleType: 1,//default
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});

  const [formErrors, setFormErrors] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Set initial tab index to 0 (Candidate)
  useEffect(() => {
    async function initializeCsrfToken() {
      await axios.get(domain.Sanctum_CSRF);
    }
    initializeCsrfToken();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    // Remove error when input changes
    if (errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
    // Set roleType based on selected tab
    if (index === 0) {
      setFormData({ ...formData, roleType: 1 }); // Candidate
    } else if (index === 1) {
      setFormData({ ...formData, roleType: 2 }); // Employer
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (
      !/^[\w.%+-]+@[\w.-]+\.[A-Z]{2,}$/i.test(formData.email) // Email validation
    ) {
      errors.email = "Enter a valid email or username";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!formData.termsAccepted) {
      errors.termsAccepted = "You must agree to the terms";
    } setFormErrors(errors);

    return errors;
  };
  // Clear errors function
  const clearErrors = () => {
    setFormErrors({});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    const formErrors = validateForm();

    // Proceed only if no validation errors exist
    if (Object.keys(formErrors).length === 0) {
      try {
        await submitRegister(formData.email, formData.password, formData.roleType);

        // Clean up after successful registration
        setFormData({
          email: '',
          password: '',
          roleType: '',
          confirmPassword: ''
        });
        clearErrors(); // Clears any validation errors if you have such a function
        // Optional: Redirect to another page, e.g., login or dashboard
        // window.location.href = "/login"; 
      } catch (error) {
        console.error("Registration error:", error);
      }
    }
  };


  const submitRegister = async (email, pass, type) => {
    try {
      const response = await axios.post(domain.CandidateRegister_URL, {
        email,
        pass,
        type
      });
      Swal.fire({
        icon: 'success',
        title: 'Registration Successful',
        text: 'You have registered successfully!',
        confirmButtonText: 'OK'
      });
      return response.data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: error.response ? error.response.data.message : error.message,
        confirmButtonText: 'Try Again'
      });

      throw new Error(error.response ? error.response.data.message : error.message);
    }
  };


  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          {/* onSelect={(index) => setActiveTab(index)} */}
          <Tabs className="wd-form-login tf-tab" selectedIndex={activeTab} onSelect={handleTabChange}>
            <h4>Create Link Free Account</h4>
            <TabList className="menu-tab">
              <Tab className="ct-tab">Candidate</Tab>
              <Tab className="ct-tab">Employer</Tab>
            </TabList>
            <div className="content-tab">
              {/* Candidate */}
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmit}>
                  <div className="ip">
                    <label>
                      Email Address<span>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder=" Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <Link
                        className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass(!showPass)}
                      />
                    </div>
                    {errors.password && <p className="error">{errors.password}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass2 ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      <Link
                        className={`password-addon ${showPass2 ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                  </div>
                  <div className="group-ant-choice st">
                    <div className="sub-ip">
                      <input
                        type="checkbox"
                        name="termsAccepted"
                        checked={formData.termsAccepted}
                        onChange={handleChange}
                      />
                      I agree to the <Link to="/termsofuse">Terms of Use</Link>
                    </div>
                    {errors.termsAccepted && <p className="error">{errors.termsAccepted}</p>}
                  </div>
                  <button type="submit">Register</button>
                  <div className="sign-up">
                    Already have an account?
                    <Link to="/login"> Login Here</Link>
                  </div>
                </form>
              </TabPanel>
              {/* Employeer */}
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmit}>
                  <div className="ip">
                    <label>
                      Email Address<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder=" Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                  <button type="submit">Register</button>
                  <div className="sign-up">
                    Already have an account?
                    <Link to="/login"> Login Here</Link>
                  </div>
                </form>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
