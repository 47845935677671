import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Header1 from "../../components/header/Header1";
import Banner04 from "../../components/banner/Banner04";
import IconBox from "../../components/iconbox";
import Employer from "../../components/employer";
import Partner from "../../components/partner";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import Location04 from "../../components/location/Location04";
import ReviewJob from "../../components/jobs/ReviewJob";
import dataLocation from "../../assets/fakeData/dataLocation";
import dataEm from "../../assets/fakeData/dataEmployers";
import dataPartner from "../../assets/fakeData/dataPartner";
import axios from 'axios';
import domain from "../../api/constent";
import TabJob from "../../components/jobs/TabJob";
import dataJobs from "../../assets/fakeData/dataJobs";
import { Box, Typography, CircularProgress } from '@mui/material';
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import MetaCategory from "../../components/dropdown/MetaCategory";
Home.propTypes = {};

function Home(props) {

  const [jobList, setDataInFeatured] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();

    fetchInfo();
  }, []);
  const fetchInfo = async () => {
    try {
      setLoading(true);

      const resp = await axios.get(domain.Job_Posting_List_URL);
      console.log(resp.data);
      setDataInFeatured(resp.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching data');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };



  const [isShow, setShow] = useState(false);

  const handlePopup = () => {
    const getPopUp = document.querySelector(".wd-popup-job-apply");
    setShow(!isShow);
    !isShow
      ? getPopUp.classList.add("modal-menu--open")
      : getPopUp.classList.remove("modal-menu--open");
  };
  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>{error}</div>;

  // console.log(loading);
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
        <CircularProgress color="primary" size={60} thickness={4} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  }
  if (error) return <Typography color="error">Error: {error}</Typography>;


  return (
    <>
      <Header1 clname="act1" handleMobile={handleMobile} />

      <Banner04 />

      {/* <JobStyle4 data={jobList} className="jobs-section-two" /> */}


      <MetaCategory />
      <TabJob data={jobList} handlePopup={handlePopup} />
      <IconBox />


      <ReviewJob />

      <Location04
        data={dataLocation}
        className="location-section-two background1"
      />

      <Employer data={dataEm} />
      <Partner data={dataPartner} />
      <Footer />
      <Gotop />
    </>
  );
}

export default Home;
