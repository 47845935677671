import React, { useState, useEffect } from "react";
import axiosDom from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import domain from "../../api/constent";
import { sweetAlert } from '../../utils/sweetAlert';
import rootSession from "../../api/session";
import axios from "axios";
function SignIn() {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({
    usernameOrEmail: "",
    password: ""
  });


  const [errors, setErrors] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [err, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Set CSRF token for Axios requests
  useEffect(() => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (csrfToken) {
      axiosDom.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const csrfToken = await axios.get(process.env.REACT_APP_ROOT_URL + '/sanctum/csrf-cookie');
      console.log(csrfToken);

      const response = await axios.post(domain.Login_URL, {
        email: formData.usernameOrEmail,
        pass: formData.password,
        type: 1 // Assuming type '1' represents password login
      });
      console.log(response);

      if (response.data.case === 1) {
        const userData = {
          id: response.data.data.id,
          email: response.data.data.email,
          type: response.data.data.type,
          createdAt: response.data.data.created_at,
          updatedAt: response.data.data.updated_at,
          token: response.data.token
        };
        navigate("/");
        localStorage.setItem("token", response.data.token);
        sessionStorage.setItem('user', JSON.stringify(userData));
        sweetAlert('Success!', 'You have logged in successfully.', 1); // Success

      } else {
        sweetAlert('Error!', 'Invalid credentials provided.', 2); // Error
        // Handle API validation errors or general issues
        setError("Invalid login credentials. Please try again.");
      }
    } catch (err) {
      // Check for validation errors and handle
      if (err.response && err.response.status === 422) {
        sweetAlert('Error!', err.response.data.errors.email ? err.response.data.errors.email[0] : 'Invalid credentials.', 2); // Error
        setErrors(err.response.data.errors); // Set validation errors from the server
      } else {
        sweetAlert('Error!', 'Invalid credentials provided.', 2); // Error

        setError(err.response ? err.response.data.message : "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <h4>Log In</h4>
            <form onSubmit={handleSubmit}>
              <div className="ip">
                <label>
                  Username or email address<span>*</span>
                </label>
                <input
                  type="text"
                  name="usernameOrEmail"
                  value={formData.usernameOrEmail}
                  onChange={(e) => setFormData({ ...formData, usernameOrEmail: e.target.value })}
                  placeholder="Email or Username"
                  required
                />
                {errors.usernameOrEmail && <p className="error">{errors.usernameOrEmail[0]}</p>}
              </div>

              <div className="ip">
                <label>
                  Password<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    className="input-form password-input"
                    name="password"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    placeholder="Password"
                    required
                  />
                  <Link
                    className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
                {errors.password && <p className="error">{errors.password[0]}</p>}
              </div>

              {/* Display general error if exists */}
              {err && <p className="error">{err}</p>}


              <div className="group-ant-choice">
                <div className="sub-ip">
                  <input type="checkbox" />
                  Remember me
                </div>
                <Link to="#" className="forgot">
                  Forgot password?
                </Link>
              </div>
              <button type="submit" disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>

              <div className="sign-up p-3">
                Not registered yet? <Link to="/createaccount">Sign Up</Link>
              </div>
              <p className="line-ip">
                <span>or sign up with</span>
              </p>

              {/* Social media login options */}
              <Link to="#" className="btn-social">
                Continue with Facebook
              </Link>
              <Link to="#" className="btn-social">
                <img src={img} alt="Google" /> Continue with Google
              </Link>
              <Link to="#" className="btn-social">
                <img src={img2} alt="Twitter" /> Continue with Twitter
              </Link>


            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
