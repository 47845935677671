import React, { useState, useEffect } from "react";
import { Button, TextField, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid, Typography, Chip } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";


import Session from '../../api/session';
import axios from '../../api/axios';
import domain from "../../api/constent";

export default function SkillComp() {
    const [openDialog, setOpenDialog] = useState(false);
    const [skillInput, setSkillInput] = useState(""); // input field value for skill search
    const [suggestedSkills, setSuggestedSkills] = useState([
        "Project Delivery",
        "Project Risk Management",
        "Microsoft Project",
        "Stakeholder Management",
        "Team Leadership",
        "Agile Methodology",
        "Leadership",
        "Conflict Resolution",
        "Problem Solving",
        "Budgeting",
        "Time Management",
        "Customer Service",
        "Teamwork",
        "Critical Thinking",
    ]); // Suggested skills (can be fetched from an API)
    const [selectedSkills, setSelectedSkills] = useState([]); // List of added skills

    // Handle input change in the search field
    const handleSkillInputChange = (e) => {
        setSkillInput(e.target.value);
    };

    // Add skill to the selected skills list
    const handleAddSkill = (skill) => {
        if (!selectedSkills.includes(skill)) {
            setSelectedSkills([...selectedSkills, skill]);
        }
    };

    // Remove skill from the selected skills list
    const handleRemoveSkill = (skill) => {
        setSelectedSkills(selectedSkills.filter((item) => item !== skill));
    };

    // Open the skill dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // Close the skill dialog without saving
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Handle save action
    const handleSaveSkills = () => {
        // Perform save action (API call or state update)
        console.log("Saved Skills:", selectedSkills);
        setOpenDialog(false);
    };



    const [lists, setList] = useState([]); // State to hold the list data
    const [loading, setLoading] = useState(true); // Loading state
    useEffect(() => {

        const userId = Session.getDataSessionStore().id;
        const userEmail = Session.getDataSessionStore().email;

        // Fetch data from API on component mount
        axios.get(`${domain.Skills_List}${userId}/${userEmail}`).then(response => {
            // console.log(response.data);
            setList(response.data.data); // Set list data from the API response
            setLoading(false); // Set loading to false once data is fetched
        }).catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);


    const styles = {
        card: {
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '16px',
            marginTop: '5px',
            maxWidth: '400px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        course: {
            fontSize: '18px',
            fontWeight: 'bold',
            margin: 0,
        },
        editButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
        },
        verifyLink: {
            color: '#007bff',
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: '500',
        },
        institution: {
            fontSize: '16px',
            color: '#333',
            marginTop: '8px',
        },
        finishedYear: {
            fontSize: '14px',
            color: '#666',
            marginTop: '4px',
        },
        highlights: {
            fontSize: '14px',
            color: '#666',
            marginTop: '4px',
        },
        badge: {
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '4px 10px',
            borderRadius: '12px',
            fontSize: '14px',
            marginRight: '8px',
            display: 'inline-block',
        },
    };



    return (
        <div>
            <div className="pt-2">
                <Typography variant="h6" component="strong" gutterBottom>
                    Skills
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                    Let employers know how valuable you can be to them.
                </Typography>

                <div>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        lists.map((item, index) => (
                            <div style={styles.card} key={item.id}>
                                <div style={styles.header}>
                                    <h3 style={styles.course}>{item.skill}</h3>
                                    <button style={styles.editButton} title="Edit">
                                        ✏️
                                    </button>
                                </div>
                                {/* <h3 style={styles.course}>{item.skill}</h3>

                                <div>
                                    {item.skill && item.skill.length > 0 && item.skill.map((skill, index) => (
                                        <span key={index} style={styles.badge}>
                                            {skill}
                                        </span>
                                    ))}
                                </div> */}
                            </div>
                        ))
                    )}
                </div>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                    startIcon={<AddIcon />}
                >
                    Add Skills
                </Button>
            </div>

            {/* Skills Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="strong">
                            Add Skills
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                        {/* Skill Search Input */}
                        <Grid item xs={12}>
                            <TextField
                                label="Search Skills"
                                variant="outlined"
                                fullWidth
                                value={skillInput}
                                onChange={handleSkillInputChange}
                                placeholder="Type skill name"
                                size="small"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: 2,
                                    },
                                }}
                            />
                        </Grid>
                        {/* Suggested Skills List */}
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong" gutterBottom>
                                Suggested Skills List
                            </Typography>
                            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                                <List>
                                    {suggestedSkills
                                        .filter((skill) => skill.toLowerCase().includes(skillInput.toLowerCase()))
                                        .map((skill) => (
                                            <ListItem
                                                key={skill}
                                                button
                                                onClick={() => handleAddSkill(skill)}
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "#f1f1f1",
                                                    },
                                                }}
                                            >
                                                <ListItemText primary={skill} />
                                            </ListItem>
                                        ))}
                                </List>
                            </Box>
                        </Grid>

                        {/* Added Skills as Badges */}
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong" gutterBottom>
                                Added Skills
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                {selectedSkills.map((skill) => (
                                    <Chip
                                        key={skill}
                                        label={skill}
                                        onDelete={() => handleRemoveSkill(skill)}
                                        color="primary"
                                        sx={{
                                            margin: "5px",
                                            "& .MuiChip-deleteIcon": {
                                                color: "#fff",
                                            },
                                        }}
                                    />
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ paddingRight: 3 }}>
                    <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveSkills}
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
