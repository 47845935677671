import axios from "axios";

axios.create({
  baseURL: process.env.REACT_APP_DB_URL,
  headers: {
    "content-Type": "application/json",
    accept: "application/json",
    "X-XSRF-TOKEN": localStorage.getItem("token"),
    "X-Request-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;
// function getCookie(name) {
//   return (
//     document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || ""
//   );
// }
// axios.interceptors.request.use((config) => {
//   config.withCredentials = true; // Ensure cookies are sent with the request
//   return config;
// });
export default axios;
