import React, { useState } from "react";
import { Button, TextField, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid, Typography, Chip } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";

export default function LanguageComp() {
    const [openDialog, setOpenDialog] = useState(false);
    const [languageInput, setLanguageInput] = useState(""); // input field value for language search
    const [suggestedLanguages, setSuggestedLanguages] = useState([
        "English",
        "Spanish",
        "French",
        "German",
        "Chinese",
        "Arabic",
        "Portuguese",
        "Russian",
        "Japanese",
        "Italian",
        "Hindi",
        "Korean",
        "Dutch",
        "Swedish",
    ]); // Suggested languages (can be fetched from an API)
    const [selectedLanguage, setSelectedLanguage] = useState(null); // Added language

    // Handle input change in the search field
    const handleLanguageInputChange = (e) => {
        setLanguageInput(e.target.value);
    };

    // Add language to the selected language
    const handleAddLanguage = (language) => {
        setSelectedLanguage(language);
    };

    // Remove selected language
    const handleRemoveLanguage = () => {
        setSelectedLanguage(null);
    };

    // Open the language dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // Close the language dialog without saving
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Handle save action
    const handleSaveLanguage = () => {
        // Perform save action (API call or state update)
        console.log("Saved Language:", selectedLanguage);
        setOpenDialog(false);
    };

    return (
        <div>
            <div className="pt-2">
                <Typography variant="h6" component="strong" gutterBottom>
                    Language
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                    Add a language to let employers know your proficiency.
                </Typography>

                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                    startIcon={<AddIcon />}
                >
                    Add Language
                </Button>
            </div>

            {/* Language Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="strong">
                            Add Language
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                        {/* Language Search Input */}
                        <Grid item xs={12}>
                            <TextField
                                label="Search Languages"
                                variant="outlined"
                                fullWidth
                                value={languageInput}
                                onChange={handleLanguageInputChange}
                                placeholder="Type language name"
                                size="small"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: 2,
                                    },
                                }}
                            />
                        </Grid>

                        {/* Suggested Languages List */}
                        <Grid item xs={12}>
                            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                                <List>
                                    {suggestedLanguages
                                        .filter((language) => language.toLowerCase().includes(languageInput.toLowerCase()))
                                        .map((language) => (
                                            <ListItem
                                                key={language}
                                                button
                                                onClick={() => handleAddLanguage(language)}
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "#f1f1f1",
                                                    },
                                                }}
                                            >
                                                <ListItemText primary={language} />
                                            </ListItem>
                                        ))}
                                </List>
                            </Box>
                        </Grid>

                        {/* Added Language as Badge */}
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong" gutterBottom>
                                Added Language
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                {selectedLanguage && (
                                    <Chip
                                        label={selectedLanguage}
                                        onDelete={handleRemoveLanguage}
                                        color="primary"
                                        sx={{
                                            margin: "5px",
                                            "& .MuiChip-deleteIcon": {
                                                color: "#fff",
                                            },
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ paddingRight: 3 }}>
                    <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveLanguage}
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
