import axios from "./axios";
import domain from "./constent";
import Session from "./session";

import { sweetAlert } from "../utils/sweetAlert";

let userId = Session.getDataSessionStore().id;
let userEmail = Session.getDataSessionStore().email;

// Profile Role
const getDetailsRoleList = async () => {
  try {
    const response = await axios.get(
      `${domain.Career_History_List}${userId}/${userEmail}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const getDetailsRoleCreate = async (formData) => {
  try {
    const response = await axios.post(domain.Career_History_Create, formData);
    getDetailsRoleList();
    sweetAlert("Success!", "You Role has been created successfully.", 1); // Success
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    sweetAlert("error!", error, 2); // Success

    throw error;
  }
};

const apiService = {
  getDetailsRoleList,
  getDetailsRoleCreate,
};

export default apiService;
