import AXIOS from "../api/axios";
import Static from "../api/constent";
function getDataSessionStore() {
  const storedData = sessionStorage.getItem("user");
  return storedData ? JSON.parse(storedData) : null;
}
function getDataSessionClear(value) {
  return sessionStorage.removeItem(value);
}
function getChecking() {
  if (sessionStorage.getItem("user") != null) {
    return true;
  }
  return false;
}
async function getCSRF() {
  return await AXIOS.get(Static.Sanctum_CSRF);
}
const rootSession = {
  getDataSessionStore,
  getCSRF,
  getDataSessionClear,
  getChecking,
};
export default rootSession;
